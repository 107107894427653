import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import './FooterMenu.scss'
import { Row, Col } from 'react-bootstrap'

function FooterMenu() {
    const { hash } = useLocation();
    return (

        <Row>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>Connect</h4>
                    <ul >
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                        <li><NavLink to="/posts">Read Our Blog</NavLink></li>
                        <li><NavLink to="/pages/live-events">Watch Live Events</NavLink></li>
                    </ul>
                </div>
            </Col>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>About us</h4>
                    <ul >
                        <li><NavHashLink className={hash === `#Mission` ? "on" : ""} to="/about#Mission">Mission</NavHashLink></li>
                        <li><NavHashLink className={hash === `#Impact` ? "on" : ""} to="/about#Impact">Impact</NavHashLink></li>
                        <li><NavHashLink className={hash === `#Partners` ? "on" : ""} to="/about#Partners">Partners</NavHashLink></li>
                        <li><NavHashLink className={hash === `#Faqs` ? "on" : ""} to="/about#Faqs">Faqs</NavHashLink></li>
                    </ul>
                </div>
            </Col>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>Resources</h4>
                    <ul >
                        <li><NavLink to="/pages/press">Press</NavLink></li>
                        <li><NavLink to="/pages/downloads">Downloads</NavLink></li>
                        <li><NavLink to="/pages/financials">financials</NavLink></li>

                    </ul>
                </div>
            </Col>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>Get Involved</h4>
                    <ul >

                        <li><NavLink to="/student-list">Sponsor a Student</NavLink></li>
                        <li><NavLink to="/peducate-lanka-fund">Donate</NavLink></li>
                        <li><NavLink to="/pages/fundraise">Fundraise</NavLink></li>
                        <li><NavLink to="/pages/volunteer">Volunteer</NavLink></li>
                        <li><NavLink to="/pages/partner">Partner</NavLink></li>
                        <li><NavLink to="/pages/get-involved">Get Involved</NavLink></li>
                    </ul>
                </div>
            </Col>
        </Row>

    )
}

export default FooterMenu
