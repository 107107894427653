/*eslint-disable*/
import React, { useEffect, useState } from "react";
import "./Payment.scss";
import moment from "moment";
import _ from "lodash";
import CancelRecurringModal from "../../../../Reusable/Modal/CancelRecurring/CancelRecurringModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../../../../store/actions/sponsor";

const Payment = (props) => {
  const { sponsorship, total } = props;
  const { payments } = sponsorship;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [allPaymentArray, setAllPaymentArray] = useState([]);
  const [openCancelRecurringModal, setOpenCancelRecurringModal] =
    useState(false);
  // Sort payments in descending order based on createdAt
  const sortedPayments = payments?.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const getPaymentPeriodMonth = (paymentArray, id) => {
    let periodLength = 0;
    let found = false;

    paymentArray?.forEach((payment) => {
      if (!found) {
        periodLength += payment.currentPlanPeriod;
        if (payment.id === id) {
          found = true;
        }
      }
    });
    return periodLength;
  };

  const getAllPaymentArrayWithModified = () => {
    let finalArray = [];
    sortedPayments.map((payment) => {
      finalArray.push({
        ...payment,
        periodLength:
          payment.currentPlanPeriod === 1
            ? moment(sponsorship.startDate)
                .add(
                  getPaymentPeriodMonth(sortedPayments, payment.id) - 1,
                  "months"
                )
                .format("MMM YYYY")
            : `${moment(sponsorship.startDate)
                .add(
                  getPaymentPeriodMonth(sortedPayments, payment.id) -
                    payment.currentPlanPeriod,
                  "months"
                )
                .format("MMM YYYY")} - ${moment(sponsorship.startDate)
                .add(
                  getPaymentPeriodMonth(sortedPayments, payment.id) - 1,
                  "months"
                )
                .format("MMM YYYY")}`,
      });
    });
    setAllPaymentArray(
      finalArray?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
  };

  useEffect(() => {
    if (payments?.length > 0) {
      getAllPaymentArrayWithModified();
    }
  }, [payments]);

  const handleConfirm = () => {
    dispatch(cancelSubscription(props.sponsorship.id, auth.token))
    .then(() => {
      toast.success("Subscription successfully canceled.");
      setOpenCancelRecurringModal(false);
    })
    .catch(() => {
      toast.error("Failed to cancel subscription.");
    });
  };

  return (
    <div className="Payment">
      {props.index === 0 && (
        <ul>
          <li>
            <strong>Next Payment Due On </strong>
            <span className="Red">
              {moment(sponsorship.dueDate).local().format("LL")}
            </span>
          </li>
          <li>
            <strong>Total Paid Amount</strong>
            {sponsorship.status === "pending" ? (
              <span>N/A</span>
            ) : (
              `$${parseInt(total)?.toLocaleString()}`
            )}
          </li>
          <li>
            <strong>Current Payment Option</strong>
            {sponsorship?.status === "pending" ? (
              <span className="orange">
                <i className="fa fa-hourglass-start"></i> Pending Payment
              </span>
            ) : sponsorship?.recurring ? (
              "Recurring"
            ) : (
              "Non-Recurring"
            )}
            {sponsorship.recurring && (
              <>
                <span style={{ marginLeft: "0.3rem" }}>(</span>
                <span
                  style={{ textDecoration: "underLine", cursor: "pointer" }}
                  onClick={() => setOpenCancelRecurringModal(true)}
                >
                  Cancel Subscription
                </span>
                <span>) </span>
              </>
            )}
          </li>
        </ul>
      )}
      {/* <h4>
        <span>PAYMENT HISTORY</span> <span> {startDate} - {endDate}</span>
      </h4> */}
      <div className="ScrollTable">
        <table>
          <thead>
            <tr>
              <th>Payment Date</th>
              {/* <th>Due Date</th> */}
              <th>Payment Type</th>
              <th>Method</th>
              <th>Payment Period</th>
              <th>Amount Paid</th>
            </tr>
          </thead>
          <tbody>
            {allPaymentArray?.length > 0 ? (
              allPaymentArray.map((payment) => {
                return (
                  <tr key={payment.id}>
                    <td>{moment(payment?.createdAt).local().format("LL")}</td>
                    {/* <td>{moment(payment?.dueDate).format("LL")}</td> */}
                    <td>{payment.recurring ? "Recurring" : "Non-Recurring"}</td>
                    <td>
                      {payment.method === "cc" && "Credit Card"}
                      {payment.method === "pp" && "Paypal"}
                      {payment.method === "ml" && "Offline"}
                      {payment.method === "gc" && "Gift Card"}
                    </td>
                    <td>
                      {`${payment.currentPlanPeriod} ${
                        payment.currentPlanPeriod === 1 ? "Month" : "Months"
                      }`}
                      <small className="datePeriod">
                        {" "}
                        (
                        {payment.currentPlanPeriod === 1
                          ? moment(sponsorship.startDate)
                              .add(
                                getPaymentPeriodMonth(
                                  sortedPayments,
                                  payment.id
                                ) - 1,
                                "months"
                              )
                              .format("MMM YYYY")
                          : `${moment(sponsorship.startDate)
                              .add(
                                getPaymentPeriodMonth(
                                  sortedPayments,
                                  payment.id
                                ) - payment.currentPlanPeriod,
                                "months"
                              )
                              .format("MMM YYYY")} - ${moment(
                              sponsorship.startDate
                            )
                              .add(
                                getPaymentPeriodMonth(
                                  sortedPayments,
                                  payment.id
                                ) - 1,
                                "months"
                              )
                              .format("MMM YYYY")}`}
                        )
                      </small>
                    </td>
                    {/* <td>${payment.amount}</td> */}
                    <td>
                      $
                      {parseInt(payment.amount - payment.tip)?.toLocaleString()}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="text-center text-muted">
                    <p>No payments yet.</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CancelRecurringModal
        btnText="Confirm"
        title="Recurring Payment Cancellation"
        description="Please confirm your cancellation for this recurring payment subscription."
        show={openCancelRecurringModal}
        handleClose={() => setOpenCancelRecurringModal(false)}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default Payment;
