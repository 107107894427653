/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import moment from "moment";

import "./StudentDetail.scss";

import photo from "../../../assets/photo.png";
import _ from "lodash";
import StudentNewsList from "../StudentNews/StudentNewsList";

import { isValidHttpUrl } from "../../../utils/func";

import addThousandSeparator from "../../../utils/addThousandSeparator";
import CopyToClipboardButton from "../../Reusable/CopyToClipboardButton/CopyToClipboardButton";

function StudentDetail(props) {
  const [fullAmount, setFullAmount] = useState(0);
  const student = props.student?.data;
  const stringDetail = student.detail;
  const detail =
    typeof stringDetail === "string" ? JSON.parse(stringDetail) : stringDetail;

  useEffect(() => {
    if (student) {
      const spShip = _.flattenDeep(
        student.sponsorships.map((spship) => {
          return spship.payments.map((pay) => pay.amount);
        })
      );
      setFullAmount(_.sum(spShip));
    }
  }, [student]);

  const olResult = [
    detail.OL_Results_English__c,
    detail.OL_Results_English__c,
    detail.OL_Results_Language_Literature__c,
    detail.OL_Results_Math__c,
    detail.OL_Results_Other_Subject1__c,
    detail.OL_Results_Other_Subject2__c,
    detail.OL_Results_Other_Subject3__c,
    detail.OL_Results_Other_Subject4__c,
    detail.OL_Results_Religion__c,
  ];

  const arr = [];
  const arrResult = [];
  olResult.map((r, i) => {
    if (r === "A" || r === "B" || r === "C" || r === "S" || r === "W") {
      arr.push(r);
    }
  });
  arr.sort();
  arr.map((r, i) => {
    const filterd = arr.filter((s) => s === r);
    const length = filterd.length > 1 ? filterd.length : "";
    arrResult.push(`${length}${filterd[0]}`);
  });

  let activeSpship = [];
  if (student.sponsorships.length > 0) {
    activeSpship = student.sponsorships.filter(
      (sponsor) => sponsor.status === "active" || sponsor.status === "pending"
    );
  }

  return (
    <div className="StudentDetail">
      <h1>{student?.firstName}'s Story</h1>
      <div dangerouslySetInnerHTML={{ __html: detail.Student_Story__c }}></div>
      <Row>
        <Col md={6}>
          <h2>
            <i className="fa fa-user"></i> PERSONAL PROFILE
          </h2>
          <Row>
            <Col md={6}>
              <div className="StrongTag">
                <strong>GENDER</strong>
                {detail.Gender__c ? detail.Gender__c : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>AMBITION</strong>
                {detail.Ambition_Aspiration__c
                  ? detail.Ambition_Aspiration__c
                  : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>HOBBIES</strong>
                {detail.Hobbies_Interests__c
                  ? detail.Hobbies_Interests__c
                  : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <div className="StrongTag">
                <strong>EDUCATE LANKA SCHOLAR SINCE</strong>
                {detail.CreatedDate
                  ? moment(student.CreatedDate).format("MM/yyyy")
                  : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>LIAISON OFFICER</strong>
                {detail.Account.Name ? detail.Account.Name : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>FUNDING RECEIVED TO DATE</strong> ${fullAmount}
              </div>
              <div className="StrongTag">
                SPONSORS
                {student.sponsorships.map((spship, i) => {
                  let userImage = null;
                  if (spship.user?.image) {
                    userImage = isValidHttpUrl(spship?.user?.image)
                      ? spship?.user?.image
                      : `${process.env.REACT_APP_SERVER}${spship?.user?.image}`;
                  }

                  if (spship.status === "active" || spship.status === "pending") {
                    return (
                      <Link
                        key={i}
                        className="Sponser"
                        to={`/sponsor-public/${spship.user.id}`}
                      >
                        <img
                          src={
                            spship.user.image && userImage ? userImage : photo
                          }
                          alt={
                            spship.user.firstName + " " + spship.user.lastName
                          }
                        />
                        {spship.user.firstName + " " + spship.user.lastName}
                      </Link>
                    );
                  } else if (spship?.user?.anonymous) {
                    return (
                      <div key={spship?.user?.id} className="Sponser">
                        <img
                          src={photo}
                          alt="Anonymous"
                        />
                        Anonymous
                      </div>
                    );
                  } else {
                    return (
                      <div key={spship?.user?.id} className="Sponser">
                        <img
                          src={
                            spship?.user?.image && userImage ? userImage : photo
                          }
                          alt={
                            spship?.user?.firstName +
                            " " +
                            spship?.user?.lastName
                          }
                        />
                        {spship?.user?.firstName + " " + spship?.user?.lastName}
                      </div>
                    );
                  }
                })}
              </div>
            </Col>
          </Row>
          <h2>
            <i className="fa fa-graduation-cap"></i> EDUCATION
          </h2>
          <Row>
            <Col md={6}>
              <div className="StrongTag">
                <strong>CURRENT ACADEMIC INSTITUTION(S) </strong>
                {detail.Current_School_Academic_Institution__c ? (
                  <div className="results">
                    {detail.Current_School_Academic_Institution__c}
                  </div>
                ) : (
                  "N/A"
                )}
              </div>
              <div className="StrongTag">
                <strong>PREVIOUS ACADEMIC INSTITUTION(S)</strong>
                {detail.Previous_School_1__c ? (
                  <div className="results">{detail.Previous_School_1__c}</div>
                ) : (
                  "N/A"
                )}
              </div>
              <div className="StrongTag">
                <strong>CURRENT GRADE</strong>
                {detail.Current_Grade__c ? (
                  <div className="results">{detail.Current_Grade__c.split('-')[1].trim()}</div>
                ) : (
                  "N/A"
                )}
              </div>
              <div className="StrongTag">
                <strong>EXAMS</strong>
                {/* <div className="results">{detail.Major_Exam_Results__c}</div> */}
                {detail.Grade_5_Exam_Year__c && (
                  <div className="results mb-1 mt-1">
                    <b>Grade 5 Scholarship </b>
                    {`(${detail.Grade_5_Exam_Year__c}) - ${detail.Grade_5_score__c}`}
                  </div>
                )}
                {detail.OL_Exam_Year__c && (
                  <div className="results mb-1 mt-1">
                    <b>Ordinary Level </b>
                    {` (${detail.OL_Exam_Year__c}) - ${_.uniq(arrResult).join(
                      ", "
                    )}`}
                  </div>
                )}
                {detail.AL_Exam_Year__c && (
                  <div className="results mb-1 mt-1">
                    <b>Advanced Level</b>
                    {` (${detail.AL_Exam_Year__c}) `}
                    <ul className="ALevel">
                      <li>
                        <div>
                          <span>{detail.AL_Subject1__c}</span>
                          <span>{detail.AL_Results_Subject1__c}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>{detail.AL_Subject2__c}</span>
                          <span>{detail.AL_Results_Subject2__c}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>{detail.AL_Subject3__c}</span>
                          <span>{detail.AL_Results_Subject3__c}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>General English </span>
                          <span>{detail.AL_General_English__c}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {/* {detail.O_L_Results__c ||
                detail.A_L_Results__c ||
                detail.University_Results__c ||
                detail.Major_Exam_Results__c ? (
                  <>
                    {detail.O_L_Results__c && (
                      <div>
                        <div>O/L Results</div>{" "}
                        <div className="results">{detail.O_L_Results__c}</div>
                      </div>
                    )}

                    {detail.A_L_Results__c && (
                      <div>
                        <div>A/L Results</div>{" "}
                        <div className="results">{detail.A_L_Results__c}</div>
                      </div>
                    )}

                    {detail.University_Results__c && (
                      <div>
                        <div>University Results</div>{" "}
                        <div className="results">
                          {detail.University_Results__c}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  "N/A"
                )} */}
              </div>
            </Col>
            <Col md={6}>
              <div className="StrongTag">
                <strong>EXTRACURRICULARS</strong>
                <div className="results">{detail.Extracurricular_1__c}</div>
                <div className="results">{detail.Extracurricular_2__c}</div>
                <div className="results">{detail.Extracurricular_3__c}</div>
                {!detail.Extracurricular_1__c &&
                  !detail.Extracurricular_2__c &&
                  !detail.Extracurricular_3__c &&
                  "N/A"}
              </div>
              <div className="StrongTag">
                <strong>ACADEMIC ACHIEVEMENTS</strong>
                {detail.Major_Academic_Achievements_1__c ||
                detail.Major_Academic_Achievements_2__c ||
                detail.Major_Academic_Achievements_3__c ? (
                  <div>
                    <div>Major Academic Achievements</div>{" "}
                    <div className="results">
                      {detail.Major_Academic_Achievements_1__c}
                    </div>
                    <div className="results">
                      {detail.Major_Academic_Achievements_2__c}
                    </div>
                    <div className="results">
                      {detail.Major_Academic_Achievements_3__c}
                    </div>
                  </div>
                ) : (
                  "N/A"
                )}
              </div>
            </Col>
          </Row>
          <h2>
            <i className="fa fa-users"></i> HOUSEHOLD INFO
          </h2>
          <Row>
            <Col md={6}>
              <h3>Father</h3>
              {/* <div className="StrongTag">
                <strong>FATHER/GUARDIAN NAME</strong>
                {detail.Father_s_Name__c ? detail.Father_s_Name__c : "N/A"}
              </div> */}
              <div className="StrongTag">
                <strong>OCCUPATION</strong>
                {detail.Father_s_Occupation__c
                  ? detail.Father_s_Occupation__c
                  : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>MONTHLY INCOME</strong>
                {detail.Fathers_Monthly_Income__c
                  ? "Rs. " + addThousandSeparator(detail.Fathers_Monthly_Income__c)
                  : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <h3>Mother</h3>
              {/* <div className="StrongTag">
                <strong>MOTHER/GUARDIAN NAME</strong>
                {detail.Mother_s_Name__c ? detail.Mother_s_Name__c : "N/A"}
              </div> */}
              <div className="StrongTag">
                <strong>OCCUPATION</strong>
                {detail.Mother_s_Occupation__c
                  ? detail.Mother_s_Occupation__c
                  : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>MONTHLY INCOME</strong>
                {detail.Mother_s_Monthly_Income__c
                  ? "Rs. " + addThousandSeparator(detail.Mother_s_Monthly_Income__c)
                  : "N/A"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Siblings</h3>
              <div className="StrongTag">
                <strong>OTHER SIBLINGS TO SUPPORT</strong>
                {detail.No_of_Siblings_and_Ages__c
                  ? +detail.No_of_Siblings_and_Ages__c
                  : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <h3>HouseHold Income</h3>
              <div className="StrongTag">
                <strong>MONTHLY</strong>
                {detail.Additional_Monthly_Household_Income__c
                  ? "Rs. " + detail.Additional_Monthly_Household_Income__c
                  : "N/A"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Additional Household AID</h3>
              <div className="StrongTag">
                <strong>SOURCE</strong>
                {detail.Additional_Household_Aid_Source__c
                  ? detail.Additional_Household_Aid_Source__c
                  : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>MONTHLY AMOUNT</strong>
                {detail.Additional_Household_Aid_Amount__c
                  ? detail.Additional_Household_Aid_Amount__c
                  : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <h3>Additional Educational AID</h3>
              <div className="StrongTag">
                <strong>SOURCE</strong>
                {detail.Additional_Education_Aid_Source__c
                  ? detail.Additional_Education_Aid_Source__c
                  : "N/A"}
              </div>
              <div className="StrongTag">
                <strong>MONTHLY AMOUNT</strong>
                {detail.Additional_Education_Aid_Amount__c
                  ? detail.Additional_Education_Aid_Amount__c
                  : "N/A"}
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={6}>
          <h2>
            <i className="fa fa-list-alt"></i> NEWS FEED
          </h2>
          <StudentNewsList />
        </Col>
        <Col md={12}>
          {activeSpship.length === 0 && (
            <div className="buttonCenterd">
              <div className="sponser need mt-4 mb-3">
                <button onClick={props.handleModalShow}>
                  <span>${student.amount}</span>
                  <div>Start sponsoring</div>
                </button>
                <span>from ${student.amount} per month</span>
              </div>
            </div>
          )}
        </Col>
      </Row>

      <div className="socialShare">
        <div className="Profile">
          <img
            src={student.image ? student.image : photo}
            alt={student.firstName}
          />
        </div>
        <div className="socialData">
          <h3>Share {student.firstName}'s Story</h3>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target={"_blank"}
            className="fb"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
            target={"_blank"}
            className="tw"
          >
            {/* <i className="fa fa-twitter" aria-hidden="true"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
          </a>
          <CopyToClipboardButton text={window.location.href} />

          {/* <a href="mailto:" className="mail">
            <i className="fa fa-envelope"></i>
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default StudentDetail;
