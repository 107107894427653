import moment from "moment";
import React from "react";

import { Link } from "react-router-dom";
import img from "../../../assets/user.png";

function SponsorshipCard(props) {
  const { sponsorship } = props;
  const { beneficiary, user } = sponsorship;
  const isDue = moment().isSameOrAfter(sponsorship?.dueDate);

  const classess = ["SponsorshipCard"];
  if (isDue && sponsorship.status === "active") {
    classess.push("due");
  }
  return (
    <div className={classess.join(" ")}>
      <div className="Images">
        <div
          className="Image"
          style={{
            backgroundImage: `url('${beneficiary?.image ? beneficiary?.image : img}')`,
          }}></div>
        <div
          className="Image"
          style={{
            backgroundImage: `url('${user?.image ? user?.image : img}')`,
          }}></div>
      </div>
      <div className="Data">
        <div className="Names">
          <div>
            <span>Student</span> {beneficiary?.firstName}
          </div>
          <div>
            <span>Sponsor</span> {user?.firstName}
          </div>
        </div>
        <div className="Tip">
          <span>Tip </span>
          {sponsorship.tip}
        </div>
        <div className="Amount">
          <span>Amount </span>
          {sponsorship.payments.find((p) => +p.id === +sponsorship.latest_payment_id)?.amount}
        </div>
        <div className="Plan">
          <div>
            <span>Type</span> {sponsorship.recurring ? "Subscribed" : "Non Subscribed"}
          </div>
          <div>
            <span>Month(s) </span> {sponsorship.currentPlanPeriod}
          </div>
          <div>
            <span>Due Date</span>
            {moment(sponsorship.dueDate).format("LL")}
          </div>
        </div>
        <div className="Status">
          <span className={sponsorship.status}>{sponsorship.status}</span>
        </div>
      </div>

      <div className="Buttons">
        <Link to={`/admin/sponsorship/${sponsorship.id}`} className="btn btn-info">
          VIEW
        </Link>
      </div>
    </div>
  );
}

export default SponsorshipCard;
