import React from "react";
import { Row, Col } from "react-bootstrap";

function TemplateEighteen({ data }) {
  return (
    <section
      id={data?.title?.trim().split(" ").join("").toLowerCase()}
      className="inContent"
    >
      {data.text1 ? (
        <div dangerouslySetInnerHTML={{ __html: data.text1 }}></div>
      ) : undefined}
      <Row className="justify-content-center">
        <Col sm={4}>
          <div dangerouslySetInnerHTML={{ __html: data.imageUpText_1 }}></div>
          <div className="d-flex justify-content-center">
            <img src={data.image_1} alt="Logo text" width="308" height="220" />
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.imageDownText_1 }} className="mt-2"></div>
        </Col>
        <Col sm={4}>
          <div dangerouslySetInnerHTML={{ __html: data.imageUpText_2 }}></div>
          <div className="d-flex justify-content-center">
            <img src={data.image_2} alt="Logo text" width="308" height="220" />
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.imageDownText_2 }} className="mt-2"></div>
        </Col>
        <Col sm={4}>
          <div dangerouslySetInnerHTML={{ __html: data.imageUpText_3 }}></div>
          <div className="d-flex justify-content-center">
            <img src={data.image_3} alt="Logo text" width="308" height="220" />
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.imageDownText_2 }} className="mt-2"></div>
        </Col>
      </Row>
      <div className="meetAll">
        {data.text2 ? (
          <div dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
        ) : undefined}
      </div>
    </section>
  );
}

export default TemplateEighteen;
