/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProgressArea from "../../components/StudentView/ProgressArea/ProgressArea";
import StudentProfile from "../../components/StudentView/StudentProfile/StudentProfile";
import StudentDetail from "../../components/StudentView/StudentDetail/StudentDetail";
import MeetMore from "../../components/StudentView/MeetMore/MeetMore";
import Loading from "../../components/Reusable/Loading/Loading";
import { createSponsorship, loginModalHandle } from "../../store/actions";
import ConfirmModal from "../../components/Reusable/Modal/ConfirmModal/ConfirmModal";
import * as actionCreators from "../../store/actions/index";
import Page404 from "../../components/Reusable/Page404/index";
import { useNavigate, useParams } from "react-router-dom";
import './StudentView.scss'

function StudentView(props) {
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [tempConfirmAlert, setTempConfirmAlert] = useState(false);
  const [tempDocList, setTempDocListConfirmAlert] = useState(false);
  const [docList, setDocList] = useState(null);
  const [amount, setAmount] = useState(null);

  const { id: paramId, refId } = useParams();
  const dispatch = useDispatch();

  const { student, stLoading, loading, data } = useSelector(
    (state) => state.students
  );
  const sponsorships = useSelector((state) => state.sponsorships);
  const account = useSelector((state) => state.account);
  const auth = useSelector((state) => state.auth);
const navigate=useNavigate()
  useEffect(() => {
    dispatch(actionCreators.getAllStudents(1, { order: "random" }));
    if (refId) {
      dispatch(actionCreators.getOneStudentFromRefId(refId));
    } else {
      dispatch(actionCreators.getOneStudent(paramId));
    }
    return () => dispatch(actionCreators.resetOneStudent());
  }, [paramId, refId]);

  useEffect(() => {
    if (sponsorships.data)
      navigate(`/sponsorship/${sponsorships?.data?.data?.id}`);
  }, [sponsorships.data]);

  useEffect(() => {
    if (student) setAmount(student?.data?.amount);
  }, [student]);

  useEffect(() => {
    if (tempConfirmAlert && auth.token) {
      setTempConfirmAlert(false);
      setConfirmAlert(true);
    }
    if (tempDocList) {
      setDocList(tempDocList);
      setTempDocListConfirmAlert(null);
    }
  }, [auth.token, tempConfirmAlert]);

  const handleModalClose = () => {
    setConfirmAlert(false);
  };
  const handleModalShow = () => {
    if (auth.token) {
      setConfirmAlert(true);
    } else {
      setTempConfirmAlert(true);
      dispatch(loginModalHandle(true));
    }
  };
  const handleConfirm = () => {
    const data = {
      beneficiaryId: student?.data.id,
      type: "student",
      month: 1,
      amount: amount,
    };
    dispatch(createSponsorship(data, auth.token));
    setConfirmAlert(false);
  };

  const handleListModalClose = () => {
    setDocList(null);
  };
  const handleListModal = (ls) => {
    if (auth.token) {
      setDocList(ls);
    } else {
      setTempDocListConfirmAlert(ls);
      dispatch(loginModalHandle(true));
    }
  };
  const handleAmount = (cal) => {
    let oldAmount = amount;
    if (cal === "+") {
      oldAmount = oldAmount + 5;
      setAmount(oldAmount);
    } else {
      oldAmount = oldAmount - 5;
      setAmount(oldAmount);
    }
  };

  let content = null;
  let loadingSpin = null;
  if (sponsorships.loading) {
    loadingSpin = <Loading page />;
  }

  if (stLoading) {
    content = <Loading page />;
  }
  if (student && student?.data.id !== 1) {
    content = (
      <div>
        <ProgressArea student={student} handleModalShow={handleModalShow} />
        <hr />
        <StudentProfile
          student={student}
          auth={auth}
          docList={docList}
          handleListModalClose={handleListModalClose}
          handleListModal={handleListModal}
        />
        <StudentDetail student={student} handleModalShow={handleModalShow} />
        <hr />
        <MeetMore loading={loading} data={data} acData={account.data} />
        <ConfirmModal
          btnText="Confirm"
          title="Student Sponsorship Confirmation"
          description="Please confirm your sponsorship commitment below"
          show={confirmAlert}
          amount={amount}
          studentAmount={student?.data?.amount}
          handleClose={handleModalClose}
          handleConfirm={handleConfirm}
          handleAmount={handleAmount}
        />
      </div>
    );
  }
  if (!loading && !student && student?.data.id !== 1) {
    content = <Page404 />;
  }

  return (
    <>
      {loadingSpin}
      {content}

      <div id="ourcommittment" class="BlueImpactArea">
        <div class="row">
            <div class="col-lg-3">
                <div class="handrend">100%</div>
            </div>
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-3">
                        <h2>IMPACT</h2>
                        <div>
                            <p>
                                We ensure the full impact of student
                                sponsorships and program donations by
                                directing 100% of funds to our scholars
                                through micro-scholarships and program
                                servicing.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <h2>TRANSPARENCY</h2>
                        <div>
                            <p>
                                We prioritize open communication and
                                reporting at the core of our program and
                                operations, ensuring complete
                                transparency for all stakeholders.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <h2>LOCAL PARTICIPATION</h2>
                        <div>
                            <p>
                                Our impact is shaped by critical input
                                and contributions from local communities
                                - our own networks of local liaisons,
                                alumni, and educators.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <h2>ACCOUNTABILITY</h2>
                        <div>
                            <p>
                                Our unique approach that combines global
                                resources with our local networks
                                empowers us to directly shape our
                                operations and be accountable for our
                                impact.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div>
                    <p >&nbsp;</p>
                    <p >
                        <span style={{fontSize:"14pt"}}>
                            These four principles guide our approach to
                            the issue we are striving to solve while
                            reinforcing our commitment to the mission.
                        </span>
                    </p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default StudentView;
