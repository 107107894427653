import { toast } from "react-toastify";
import axios from "../../axios";

import {
  INIT_USER_REGISTER,
  SUCCESS_USER_REGISTER,
  FAIL_USER_REGISTER,
  INIT_USER_EDIT,
  SUCCESS_USER_EDIT,
  FAIL_USER_EDIT,
  INIT_USER_LOGIN,
  SUCCESS_USER_LOGIN,
  FAIL_USER_LOGIN,
  INIT_ME,
  SUCCESS_ME,
  FAIL_ME,
  AUTH_CHECK,
  SUCCESS_GET_ALL_USERS,
  INIT_GET_ALL_USERS,
  FAIL_GET_ALL_USERS,
  REMOVE_USER_UPDATE_DATA,
  INIT_IMPERSONATE,
  SUCCESS_IMPERSONATE,
  FAIL_IMPERSONATE
} from "./actionsTypes";

const initUserRegister = () => ({
  type: INIT_USER_REGISTER,
});
const successUserRegister = (data) => ({
  type: SUCCESS_USER_REGISTER,
  data,
});
const failUserRegister = (err) => ({
  type: FAIL_USER_REGISTER,
  err,
});
const initUserEdit = () => ({
  type: INIT_USER_EDIT,
});
const successUserEdit = (data) => ({
  type: SUCCESS_USER_EDIT,
  data,
});
const failUserEdit = (err) => ({
  type: FAIL_USER_EDIT,
  err,
});
const initUserLogin = () => ({
  type: INIT_USER_LOGIN,
});
const successUserLogin = (data) => ({
  type: SUCCESS_USER_LOGIN,
  data,
});
const failUserLogin = (err) => ({
  type: FAIL_USER_LOGIN,
  err,
});
const initMe = () => ({
  type: INIT_ME,
});
const successMe = (data) => ({
  type: SUCCESS_ME,
  data,
});
const failMe = (err) => ({
  type: FAIL_ME,
  err,
});

const initImpersonate = () => ({
  type: INIT_IMPERSONATE,
})
const successImpersonate = (data) => ({
  type: SUCCESS_IMPERSONATE,
  data
})
const failImpersonate = () => ({
  type: FAIL_IMPERSONATE,
})
export const authCheck = (token, impersonateToken) => ({
  type: AUTH_CHECK,
  token,
  impersonateToken
});
const initGetAllUsers = () => ({
  type: INIT_GET_ALL_USERS,
});
const successGetAllUsers = (data) => ({
  type: SUCCESS_GET_ALL_USERS,
  data,
});
const failGetAllUsers = (error) => ({
  type: FAIL_GET_ALL_USERS,
  error,
});
export const registerUser = (data) => {
  return (dispatch) => {
    dispatch(initUserRegister());
    axios
      .post("auth/signup", data)
      .then((res) => {
        console.log(res);
        toast.success("Successfully signed up.", {
          autoClose: 15000,
        });
        dispatch(successUserRegister(res.data));
        dispatch(
          loginUser({
            email: res.data.data.email,
            password: data.password,
          })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          autoClose: 15000,
        });

        dispatch(failUserRegister(err));
      });
  };
};
export const editUser = (data, token) => {
  return (dispatch) => {
    dispatch(initUserEdit());
    axios
      .put("auth/edit", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        toast.success("User updated successfully.", { autoClose: 8000 });
        dispatch(successUserEdit(res.data));
        dispatch(getMe(token));
      })
      .catch((err) => {
        dispatch(failUserEdit(err));
      });
  };
};
export const removeRegData = () => ({
  type: REMOVE_USER_UPDATE_DATA,
});
export const uploadUserImage = (userImage, token) => {
  const formData = new FormData();
  formData.append("userImage", userImage);

  return (dispatch) => {
    dispatch(initUserEdit());
    axios
      .put("auth/uploadUserImage", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success("Image upload successful", {
          autoClose: 15000,
        });
        dispatch(successUserEdit(res.data));
        dispatch(getMe(token));
      })
      .catch((err) => {
        dispatch(failUserEdit(err));
      });
  };
};
export const loginUser = (data) => {
  return (dispatch) => {
    dispatch(initUserLogin());
    axios
      .post("auth/signin", data)
      .then((res) => {
        toast.success("Successfully signed in.", {
          autoClose: 15000,
        });
        localStorage.setItem("token", res.data.token);
        dispatch(getMe(res.data.token));
        dispatch(successUserLogin(res.data));
      })
      .catch((err) => {
        dispatch(failUserLogin(err));
      });
  };
};
export const loginFbUser = (user) => {
  let token = null;
  if (user._token) {
    token = user._token.accessToken;
  }
  return (dispatch) => {
    dispatch(initUserLogin());
    axios
      .post(`auth/facebook/`, {
        token: token,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        dispatch(getMe(res.data.token));
        dispatch(successUserLogin(res.data));
      })
      .catch((err) => {
        dispatch(failUserLogin(err));
      });
  };
};
export const loginGoogleUser = (token) => {

  return (dispatch) => {
    dispatch(initUserLogin());
    axios
      .post(`auth/google/`, { token })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        dispatch(getMe(res.data.token));
        dispatch(successUserLogin(res.data));
      })
      .catch((err) => {
        dispatch(failUserLogin(err));
      });
  };
};
export const getMe = (token, impersonateToken = null) => async (dispatch) => {
  try {
    dispatch(initMe());
    let res = await axios.get("auth/me",{})
 
    dispatch(authCheck(token, impersonateToken = null));
    dispatch(successMe(res.data));
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("impersonateToken");
    dispatch(authCheck(null));
    dispatch(failMe(error));
  }
};
export const getAllUsers = (filters) => async (dispatch) => {
  try {
    dispatch(initGetAllUsers());
    const users = await axios.get("auth/get-all-users", {
      params: filters,
    });
    dispatch(successGetAllUsers(users?.data?.data));
  } catch (error) {
    dispatch(failGetAllUsers(error));
  }
};
/**
 * User impersonate required two params 
 * @param {Object} payload - expect should have two params
 * @param {userId} payload.userId this is user id, that need to impersonate  
 * @param {password} payload.password this is already logged user password (most of the time admin password)  
 * @returns 
 */
export const impersonateUser = (payload) => async (dispatch) => {
  try {
    dispatch(initImpersonate());
    const res = await axios.post(`auth/impersonate/${payload.userId}`, {
      adminPassword: payload.password,
    });
    const token = localStorage.getItem('token')
    localStorage.setItem('impersonateToken', res.data.token)
    dispatch(getMe(token, res.data.token));
    dispatch(successImpersonate(res.data));
    return "success"
  } catch (error) {
    dispatch(failImpersonate(error));
    return "fail"
  }
};