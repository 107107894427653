import React from "react";
import { Row, Col } from "react-bootstrap";
function TemplateNine({ data }) {
  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()} style={{backgroundColor:data?.backgroundColor}}>
      <div className="inContent">
        <div className="text-heading-section">
          <div className="section-header text-center mt-5 mb-5">{data?.title}</div>
          <Row className="pt-3 justify-content-center">
            {data?.tags?.map((tag, i) => (
              <Col sm={3} key={i} className="mb-5">
                <div className="imageRoundWithTitle">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${tag?.image})`,
                    }}
                  ></div>
                  <div>
                    {tag?.title && <h5 className="blueBoldText">{tag?.title}</h5>}
                    {tag?.text && <p className="mb-2">{tag?.text}</p>}
                    {tag?.url || tag?.linkText ? (
                      <a href={tag?.url}>{tag?.linkText}</a>
                    ) : undefined}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {data?.urlTitle ? (
            <Row>
              <a href={data.url} className="LinkLineButton">
                {data.urlTitle}
              </a>
            </Row>
          ) : undefined}
        </div>
      </div>
    </section>
  );
}

export default TemplateNine;
