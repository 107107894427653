import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./Navigation.scss";

import Loading from "../../../Reusable/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { loginModalHandle } from "../../../../store/actions/index";

function Navigation(props) {
  const impersonateToken = localStorage.getItem("impersonateToken");
  const token = localStorage.getItem("token");
  const { list } = useSelector((state) => state.pages);
  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    props.authCheck(null, null);
    navigate("/");
    window.location.reload();
  };
  const handleExitImpersonate = () => {
    localStorage.removeItem("impersonateToken");
    props.authCheck(token, null);
    navigate("/admin/dashboard");
  };
  const dispatch = useDispatch();

  const findPage = (key, skipOne = false) => {
    if (skipOne) {
      const hasItem = list?.filter((d) => d.group === key);
      return hasItem.filter((d, i) => i !== 0);
    }
    return list?.filter((d) => d.group === key);
  };

  return (
    <ul className={`Navigation ${props.className}`} onClick={props.hide}>
      <li>
        <NavLink to="/student-list">Sponsor a Student</NavLink>
      </li>
      <li>
        <NavLink to="/educate-lanka-fund">Donate</NavLink>
        <ul>
          {findPage("/educate-lanka-fund")?.length ? (
            <li>
              <NavLink to="/educate-lanka-fund">
                {findPage("/educate-lanka-fund")[0].name}
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {findPage("/pledge-your-birthday")?.length ? (
            <li>
              <NavLink to="/pledge-your-birthday">Donate your birthday</NavLink>
            </li>
          ) : (
            ""
          )}
          {findPage("/buy-a-gift-card")?.length ? (
            <li>
              <NavLink to="/buy-a-gift-card">
                {findPage("/buy-a-gift-card")[0].name}
              </NavLink>
            </li>
          ) : (
            ""
          )}

          <li>
            <NavLink to="/create-campaign">Start a fundraiser</NavLink>
          </li>
        </ul>
      </li>
      {findPage("/involved")?.length ? (
        <li>
          <NavLink to={findPage("/involved")[0].link}>
            {findPage("/involved")[0].name}
          </NavLink>
          {findPage("/involved", true)?.length ? (
            <ul>
              {findPage("/involved", true).map((obj, i) => (
                <li key={i}>
                  <NavLink to={obj.link}>{obj.name}</NavLink>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </li>
      ) : (
        ""
      )}
      {findPage("/about")?.length ? (
        <li>
          <NavLink to={findPage("/about")[0].link}>
            {findPage("/about")[0].name}
          </NavLink>
          {findPage("/about", true)?.length ? (
            <ul>
              {findPage("/about", true).map((obj, i) => (
                <li key={i}>
                  <NavLink to={obj.link}>{obj.name}</NavLink>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </li>
      ) : (
        ""
      )}
      {findPage("/contact-us")?.length ? (
        <li>
          <NavLink to="/contact-us">{findPage("/contact-us")[0].name}</NavLink>
        </li>
      ) : (
        ""
      )}
      {props.meLoading ? (
        <li>
          <Loading button />
        </li>
      ) : props.token ? (
        <li className="bordered">
          <button>
            {props.me ? props.me.data.firstName : "N/A"}{" "}
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </button>
          <ul className="blue">
            {props.me?.data?.type === 1 ? (
              <li>
                <NavLink to={`/admin/dashboard`}>Dashboard</NavLink>
              </li>
            ) : null}
            <li>
              <NavLink to={`/sponsor/me`}>Private Profile</NavLink>
            </li>

            <li>
              {impersonateToken ? (
                <span onClick={handleExitImpersonate}>
                  Exit impersonate ({props.me?.data?.firstName})
                </span>
              ) : (
                <span onClick={() => handleLogout()}>Logout</span>
              )}
            </li>
          </ul>
        </li>
      ) : (
        <li className="bordered">
          <button
            onClick={() => {
              dispatch(loginModalHandle(true));
            }}
          >
            Sign In
          </button>
        </li>
      )}
    </ul>
  );
}

export default Navigation;
