import React from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SocialMediaLinks from "./SocialMedia/SocialMediaLinks";
import InviteFriends from "./InviteFriends/InviteFriends";
import FooterMenu from "./FooterMenu/FooterMenu";
import "./Footer.scss";
import moment from "moment";

function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <Row className="justify-content-between">
          <Col md={7}>
            <FooterMenu />
          </Col>
          <Col md={4}>
            <SocialMediaLinks />
        
            
            <InviteFriends />
          </Col>
        </Row>
      </div>
      <div className="wrapper line">
        <Row className="customized">
          <Col>
            <div className="link">
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              <NavLink className="bl" to="/terms-and-conditions">Terms and Conditions</NavLink>
              {/* <Link to="">Get our awesome emails!</Link> */}
              <div className="weLove"><span>We’d love to hear from you! Email us at </span><a className="p-0" href="mailto:info@educatelanka.org">info@educatelanka.org</a></div>
            </div>
            
          </Col>
          <Col className="text-right" style={{letterSpacing: '.7px'}}>
          Educate Lanka Foundation is a 501(c)(3) non-profit. EIN:&nbsp; 26-1472911 &nbsp; &nbsp;|&nbsp; &nbsp; &copy; {moment().format("YYYY")}
            {/* Copyrights  <strong>Educate Lanka Foundation</strong>. */}
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
