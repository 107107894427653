import React, { useState, useEffect } from "react";
import { Link, useMatch, useLocation } from "react-router-dom";
import "./Header.scss";
import ElcLogo from "../../../assets/elc-logo.png";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/actions/index";

import Navigation from "./Navigation/Navigation";

function Header(props) {
  const [toggle, setToggle] = useState(false);
  const [headerTransparent, setHeaderTransparent] = useState(true);
  let campaignMatch = useMatch("/campaign/:slug");
  let aboutPagesMatch = useMatch("/about/:slug");
  let pagesMatch = useMatch("/page/:slug");
  let postsMatch = useMatch("/posts/:slug");
  let involvedMatch = useMatch("/involved/:slug");
  const match=campaignMatch||aboutPagesMatch||pagesMatch||postsMatch||involvedMatch
  let { pathname } = useLocation();

  const handleTogle = () => {
    setToggle(!toggle);
  };
  const handleHide = () => {
    setToggle(false);
  };
  useEffect(() => {
    const path = pathname;
    if (
      path === "/" ||
      path === "/educate-lanka-fund" ||
      path === "/pledge-your-birthday" ||
      path === "/buy-a-gift-card" ||
      path === "/contact-us"||
      path === "/about"||
      path === "/page"||
      path === "/posts"||
      path === "/involved"
    ) {
      setHeaderTransparent(false);
    } else {
      setHeaderTransparent(true);
    }
  }, [pathname]);

  return (
    <div
      className={`Header ${match ? null : headerTransparent ? "relative" : null
        }`}
    >
      <div className="wrapper">
        <div className="logo">
          <Link to="/">
            <img src={ElcLogo} alt="Elc log" />
          </Link>
        </div>
        <div
          className={`toggle ${toggle ? "Show" : "hide"}`}
          onClick={handleTogle}
        >
          <i className="fa fa-bars " aria-hidden="true"></i>
        </div>
        <Navigation
          cmspage={props.cmspage}
          authCheck={props.authCheck}
          token={props.token}
          me={props.me}
          meLoading={props.meLoading}
          hide={handleHide}
          className={toggle ? "Show" : "hide"}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  ...state.auth,
  cmspage: state.cmspage,
});
const mapDispatchToProps = (dispatch) => ({
  authCheck: (token, impersonateToken) => dispatch(actionCreator.authCheck(token, impersonateToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
