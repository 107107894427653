import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImgPhoto from "../../../assets/photo.png";
import SriLankaMap from "../../Reusable/SriLankaMap/SriLankaMap";
import "./Profile.scss";

import _ from "lodash";
import moment from "moment";

function Profile(props) {
  const { sponsor } = props;

  let district = _.union(
    _.compact(
      sponsor.sponsorships.map((sp) => {
        const stringDetail = sp.beneficiary.detail;
        return typeof stringDetail === "string"
          ? JSON.parse(stringDetail).District__c
          : stringDetail.District__c;
      })
    )
  );
  // if (props.spLoading) {
  //   studentLink = <Loading cycle />;
  // }

  let studentLink = sponsor.sponsorships.map((sp) => {
    const beneficiary = sp.beneficiary;
    return (
      <Link key={beneficiary.id} to={`/student/${beneficiary.id}`}>
        {" "}
        {beneficiary.firstName}{" "}
      </Link>
    );
  });

  return (
    <div className="Profile mt-5">
      <Row>
        <Col lg={8}>
          <Row>
            <Col md={3}>
              <div className="Image">
                {sponsor.image ? (
                  <img
                    src={sponsor.image}
                    alt={sponsor.firstName + " " + sponsor.lastName}
                  />
                ) : (
                  <img
                    src={ImgPhoto}
                    alt={sponsor.firstName + " " + sponsor.lastName}
                  />
                )}
              </div>
            </Col>
            <Col md={9}>
              <div className="SponsorDetail">
                <h1>{sponsor.firstName + " " + sponsor.lastName}</h1>
                <h2>
                  {sponsor.city &&
                    sponsor.city + "," + sponsor.city &&
                    sponsor.city}
                </h2>
                <div className="tag">
                  <span>PREMIUM SPONSOR</span>
                  <span>ELF MEMBER</span>
                </div>
                <div className="connection">
                  Connect on
                  <a
                    href={sponsor.linkedin ? sponsor.linkedin : "#"}
                    className="in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    href={sponsor.twitter ? sponsor.twitter : "#"}
                    className="tw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    href={sponsor.facebook ? sponsor.facebook : "#"}
                    className="fb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="generalDetail mt-5">
                <h1>{sponsor.firstName}'S STORY</h1>
                <div>
                  <b>{sponsor.firstName}</b> became an Educate Lanka sponsor on{" "}
                  {moment(sponsor.joinDate).format("LL")} with the sponsorship
                  of
                  <div className="stList">{studentLink} </div>
                  education starting from grade ______.
                  {sponsor.firstName} is Educate Lanka’s _____th sponsor.
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <div className="SponserMap">
            <h1>{sponsor.firstName}'S SPONSORSHIP</h1>
            <div>
              {sponsor.firstName} has sponsored for{" "}
              <strong>{sponsor.sponsorships.length}</strong> from{" "}
              <strong>{district.length} districts</strong> in{" "}
              <strong>Sri Lanka</strong> since joining as a sponsor in{" "}
              <strong>{moment(sponsor.createdAt).format("MMMM YYYY")}.</strong>
            </div>
            <SriLankaMap district={district} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Profile;
