import React from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteDistributionEvent, getEventList } from "../../../store/actions";
import moment from "moment/moment";

const List = () => {
  const dispatch = useDispatch();
  const { eventList } = useSelector((state) => state.distributionEvent);
  useEffect(() => {
    dispatch(getEventList());
  }, []);

  return (
    <>
      <div className="HeaderTextCMS">
        Distribution Event
        <div className="Filters">
          <Link className="btn btn-success" to="create">
            ADD
          </Link>
        </div>
      </div>
      <div className="WhiteContent">
        <table width="100%">
          <thead>
            <tr>
              <th>Name</th>
              <th>createdAt</th>
              <th>date</th>
              <th width="120"></th>
            </tr>
          </thead>
          <tbody>
            {eventList?.data?.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{moment(item.createdAt).format("LL")}</td>
                <td>{moment(item.date).format("LL")}</td>
                <td>
                  <Link className="btn btn-info mr-2" to={`edit/${item.id}`}>
                    <i className="fa fa-pencil"></i>
                  </Link>
                  <Button
                    variant="danger"
                    onClick={()=>dispatch(deleteDistributionEvent(item.id))}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default List;
