import React from "react";

function SponsorshipAgreement() {
  return (
    <div className="container mt-5 mb-5">
      <h1>Sponsorship Agreement</h1>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nam voluptatibus quia,
      laboriosam amet qui repudiandae consectetur similique ratione reprehenderit.
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, esse praesentium omnis
        consequuntur commodi a unde eligendi delectus veritatis, maxime quasi error soluta dolor,
        recusandae tempore facere minima. Veniam, ipsam ducimus vel blanditiis nostrum dolorem animi
        minus perspiciatis iste pariatur. Quo debitis impedit, distinctio dolorem aliquid sit est
        sint laborum rerum animi cupiditate excepturi doloribus itaque doloremque sed veritatis
        neque?
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla expedita cupiditate repellat
        enim tempore perspiciatis! Aliquam modi amet eligendi suscipit exercitationem eos incidunt,
        expedita aut illum quisquam nostrum debitis pariatur commodi ipsum itaque quibusdam quod
        ipsa eaque tempora quos consequuntur sed hic facilis? Alias quod molestiae reiciendis nobis
        sint cum autem molestias, aliquam veniam magni esse impedit deserunt ullam perspiciatis
        repellat tempora quia corporis facere vel voluptatem doloremque ipsum! Ratione ducimus
        necessitatibus sunt odio libero quos perferendis minus consequatur. Pariatur eius,
        voluptatem amet inventore aliquam consequuntur neque adipisci ipsa iure molestias nemo? Vero
        cum perspiciatis id amet minima vitae ducimus, facere perferendis dicta fuga possimus
        eligendi sint corrupti error qui temporibus. Saepe hic voluptas consequuntur dolorem rerum
        eum provident, praesentium odio, doloribus dolore fugiat sit, modi eaque dolor quam. Ut,
        illum enim officiis perspiciatis consequatur reiciendis sint. Unde cum ex delectus? Nobis
        iste eaque commodi cum, quia numquam dolore temporibus reiciendis, natus rerum nihil odio,
        deserunt fugit cupiditate modi similique omnis! Consequatur aperiam quo ea, ab dolor et
        similique? Cum repudiandae officiis saepe ipsam explicabo vel sed non obcaecati nam tempora
        tempore quisquam sequi nulla odio nesciunt, porro illum mollitia voluptates distinctio. Id
        aliquid culpa quam placeat dolorem doloribus consectetur.
      </p>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus, minus?</p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta qui cum reiciendis debitis
        blanditiis est voluptatum voluptates at tempora dolorem nostrum, sed quis eius beatae! Nam
        optio beatae explicabo tenetur fuga rem, expedita facilis, corporis deleniti unde autem
        vitae ex dolorem, fugit aspernatur tempore accusamus molestias ipsum culpa harum a odit! Ut
        dolores et deleniti porro adipisci impedit similique possimus soluta officia maiores
        doloremque, hic saepe? Est mollitia, quos veritatis facere a obcaecati debitis dolore
        aliquam quas id tempore quae, explicabo cupiditate placeat molestiae hic dignissimos
        reiciendis? Aut pariatur, aliquid ullam ex quod veniam sunt, laudantium quaerat quis
        architecto facilis repellat, deleniti ab totam officia natus incidunt nemo delectus
        doloribus unde a soluta amet explicabo. Voluptatem distinctio eveniet consequatur fugiat
        totam neque, praesentium iure quia laborum, provident, porro quisquam modi cumque deserunt
        doloremque ratione. Tempora suscipit aliquid libero repellat, nesciunt quas sint pariatur
        vero est aliquam! Impedit, quo sint tempora aliquam, quos molestias quis itaque sapiente
        alias fugiat quae mollitia possimus maiores id! Itaque totam excepturi sequi impedit rerum
        perferendis enim sunt, voluptas aut dolorem voluptatibus. Veniam natus doloribus corporis
        sed sit perspiciatis maiores atque ducimus labore, sint voluptatem libero fuga iste
        blanditiis dolor quis, reiciendis porro perferendis magnam autem quam rerum. Quod
        consequuntur qui, est quam commodi non necessitatibus, hic eveniet maiores neque impedit
        consequatur eius, ipsam quo iste. Maiores eligendi modi unde quis enim beatae sapiente
        debitis ratione ullam rem adipisci, mollitia accusamus aperiam recusandae assumenda fuga,
        sed magnam, esse sint sequi harum laudantium quos minus. Asperiores officia dolores minus,
        soluta voluptas repellendus fugit ipsam omnis recusandae nam dolorem, provident eaque!
        Reprehenderit ut obcaecati amet veniam culpa at ipsa dignissimos magnam laborum saepe ea
        enim, sapiente commodi nisi nam, blanditiis temporibus recusandae nobis repellat unde cum
        excepturi atque nihil? Iure nemo voluptatum, nesciunt error veritatis rerum in aperiam
        incidunt maiores earum perferendis expedita repudiandae officiis ad quos distinctio
        recusandae. Esse, quod? Alias nesciunt, eos magni provident sequi rerum quisquam sed libero
        veritatis quia vero blanditiis facere ratione sint fugit? Quibusdam et accusantium
        consectetur quisquam numquam ad voluptatibus quas impedit provident, molestias dignissimos
        tempore totam, sint eveniet facere beatae dolorum delectus tenetur, recusandae rem! Ducimus
        modi eveniet illum officiis! Aperiam cupiditate incidunt excepturi aliquid magni voluptate!
        Ipsum autem possimus sed tempora debitis cupiditate aspernatur expedita dolorem, et facilis
        adipisci provident reprehenderit totam, excepturi voluptatem voluptas deserunt. Inventore
        nemo est sequi aperiam similique explicabo recusandae? Repudiandae libero eius eaque
        deserunt.
      </p>
    </div>
  );
}

export default SponsorshipAgreement;
