import { Button } from "react-bootstrap";
import moment from "moment";
import React from "react";
import img from "../../../assets/user.png";
import { useSelector } from "react-redux";
import Loading from "../../Reusable/Loading/Loading";
import { Link } from "react-router-dom";

function ViewPlan(props) {
  const { data: chat, loading: chatLoading } = useSelector(
    (state) => state.chat
  );
  const { sponsorship } = props;
  const { beneficiary, user, currentPayment } = sponsorship;
  let paymentMethod = null;
  if (currentPayment?.method === "pp") paymentMethod = "Paypal";
  if (currentPayment?.method === "cc") paymentMethod = "Credit Card";
  if (currentPayment?.method === "ml") paymentMethod = "Offline";
  if (currentPayment?.method === "gc") paymentMethod = "Gift Card";

  const isDue = moment().isSameOrAfter(sponsorship?.dueDate);

  return (
    <div className="ViewPlan">
      <div className="Images">
        <div
          className="Image"
          style={{
            backgroundImage: `url('${
              beneficiary?.image ? beneficiary?.image : img
            }')`,
          }}
        >
          <div className="Data">
            <div>
              <span>Student : </span> {beneficiary?.firstName}{" "}
              {beneficiary?.detail?.LastName}
            </div>
            <div>
              <span>Join Date : </span>
              {moment(beneficiary?.detail?.CreatedDate).format("LL")}
            </div>
            <div>
              <Link to={`/student/${beneficiary.id}`}>VIEW PROFILE</Link>
            </div>
          </div>
        </div>
        <div
          className="Image"
          style={{
            backgroundImage: `url('${user?.image ? user?.image : img}')`,
          }}
        >
          <div className="Data">
            <div>
              <span>Sponsor : </span> {user?.firstName} {user?.lastName}
            </div>
            <div>
              <span>Join Date : </span>
              {moment(user?.createdAt).format("LL")}
            </div>
            <div>
              <Link to={`/admin/users/${user?.id}`}>VIEW PROFILE</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="DetailsBox">
        <ul>
          {sponsorship.beneficiary.type !== "elf" && (
            <li className={isDue ? "due" : ""}>
              <strong>Due Date :</strong>
              {moment(sponsorship?.dueDate).format("LLL")}{" "}
              {isDue && sponsorship.status === "active" && (
                <span>Payment Due</span>
              )}
            </li>
          )}

          <li>
            <strong>Start Date :</strong>
            {moment(sponsorship?.startDate).format("LLL")}{" "}
          </li>
          <li>
            <strong>Created At : </strong>
            {moment(sponsorship?.createdAt).format("LLL")}
          </li>
          <li>
            <strong>Last updated At : </strong>
            {moment(sponsorship?.updatedAt).format("LLL")}
          </li>
          <li>
            <strong>Last selected Month(s): </strong>
            {sponsorship?.currentPlanPeriod}
          </li>
          <li>
            <strong>Last payment Type: </strong>
            {sponsorship?.recurring ? "Recurring" : "Not Recurring"}
          </li>

          <li>
            <strong>Last payment Method: </strong>
            {paymentMethod}
          </li>
          <li>
            <strong>Last payment Amount: </strong>
            {currentPayment?.amount}
          </li>
          <li>
            <strong>Last payment tip: </strong>
            {sponsorship?.tip}
          </li>
          <li>
            <strong>Total payments: </strong>
            {sponsorship?.payments.reduce((accumulator,obj)=>accumulator+obj.amount,0)}
          </li>
          {sponsorship?.cancelledAt &&
            <li>
              <strong>Canceled At:</strong>
              {moment(sponsorship?.cancelledAt).format("LLL")}
            </li>
          }
          {sponsorship?.sponsorCancelationNote &&
            <li>
              <strong>Cancelation Note:</strong>
              {sponsorship.sponsorCancelationNote}
            </li>
          }
          <li className="mt-3 mb-3">
            {chatLoading ? (
              <Loading cycle />
            ) : (
              sponsorship?.user?.id !== 1 && (
                <Button onClick={props.handleChat} variant="info">
                  <i className="fa fa-comments"></i>{" "}
                  {chat.length ? "VIEW CHAT" : "START CHAT"}{" "}
                </Button>
              )
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ViewPlan;
