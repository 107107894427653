import React from "react";

function SponsorshipRelationshipAgreement() {
  return (
    <div className="container mt-5 mb-5">
      <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi enim aliquid rem.</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam repellat explicabo cupiditate
        quam eaque dolorem labore, repudiandae quibusdam odit vero mollitia nesciunt, suscipit
        molestiae esse qui omnis a ipsa? Illo omnis quod voluptates soluta beatae debitis recusandae
        atque consectetur minus, quidem dolor consequatur unde magnam voluptatibus blanditiis
        facilis molestias placeat doloremque in itaque qui? Nobis ex magni, natus sunt aliquam est
        laborum suscipit rerum labore doloribus nesciunt ullam, reiciendis harum amet debitis
        explicabo excepturi error, nisi impedit. Quisquam, explicabo doloribus! Rem cum amet
        similique vel maxime sint, consectetur, temporibus dolore tenetur saepe dolorum optio
        voluptate exercitationem quia incidunt voluptates officiis! Ipsam, ullam voluptatibus at
        ipsum molestias repudiandae quis officiis debitis quas, consequatur nihil necessitatibus
        quisquam. Modi sapiente officia error quos exercitationem porro ducimus architecto
        repellendus nihil fuga earum dolorem labore repudiandae, est ab a aperiam debitis,
        perferendis vero neque. Consequuntur, libero odit architecto facere veritatis fugit,
        sapiente nobis aspernatur a veniam ut doloremque eos at voluptate ea corrupti. Voluptates,
        quasi unde quibusdam dolor, id consectetur recusandae quo, autem error illo eius sequi quos
        quia fuga ab in aliquid? Vel repudiandae fuga non, illo perferendis recusandae labore
        assumenda expedita aperiam velit maiores accusantium debitis, explicabo reprehenderit,
        sapiente hic porro placeat qui.
      </p>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eos, doloribus laborum. Corporis
        expedita, voluptate magnam accusamus iure nostrum animi, ipsam molestias, officia
        consequuntur eaque voluptates dolor tempore quam molestiae inventore laudantium consectetur
        debitis fugiat nemo laboriosam. Est, ratione soluta voluptates odit porro neque culpa,
        assumenda, officiis voluptatibus provident vitae ex! Eos rerum reiciendis earum nostrum ab
        placeat reprehenderit sequi facilis nisi, qui sapiente aut praesentium maiores, adipisci
        tenetur quisquam voluptate sit. Dolorem quia ex eum! Est inventore vitae sapiente esse,
        earum officiis expedita, voluptatum minus voluptate sit veniam saepe illo, nobis enim
        ducimus incidunt omnis praesentium corporis mollitia? Tempora, neque!
      </p>
    </div>
  );
}

export default SponsorshipRelationshipAgreement;
